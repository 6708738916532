import React, {Component} from "react";
import {withTranslation} from "react-i18next";

class NotFound extends Component {
    render() {
        const {t} = this.props;
        let text = t(`notFound.${[this.props.type + '']}`);
        return (
            <p className="empty-list">- {text} -</p>
        );
    }
}

NotFound.defaultProps = {
    type: "dataNotFound"
};

export default withTranslation()(NotFound);
