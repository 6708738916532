import React from "react";
import "./_no-role-component.scss";
import Card from "./basics/card/Card";
import {logout} from "../utils/UserUtils";
import {redirect} from "../actions/historyActions";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import Button from "./basics/button/Button";

class NoRoleComponent extends React.Component {

    onLogoutClick = () => {
        logout();
    };

    onBackClick = () => {
        const {dispatch} = this.props;
        dispatch(redirect("/"));
    };

    render() {
        const {t, isAuthenticated} = this.props;
        return (
            <div className={"no-role-component"}>
                <Row bsPrefix={"row h-100 justify-content-center align-items-center w-100 mx-auto"}>
                    <Col lg={6}>
                        <Card>
                            <h4>{t('noRoleComponent.accessForbidden')}</h4>
                            <p>
                                {t("noRoleComponent.chooseAction")}{' '}
                                <Button type={"button"} onClick={this.onBackClick}>
                                    {t("noRoleComponent.homePage")}
                                </Button>
                                {
                                    isAuthenticated &&
                                    <>
                                        {' '}{t("noRoleComponent.or")}{' '}
                                        <Button type={"button"} onClick={this.onLogoutClick}>
                                            {t("noRoleComponent.logout")}
                                        </Button>
                                    </>
                                }
                            </p>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }

}

NoRoleComponent = connect(state => ({
    isAuthenticated: state.user.isAuthenticated
}))(NoRoleComponent);
export default withTranslation()(NoRoleComponent);
