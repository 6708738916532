import React, {Component} from "react";
import ReactTooltip from "react-tooltip"
import {myID} from "../libs/generateID";

export class Tooltip extends Component {
    render() {
        let generatedID = myID();//shortid.generate();
        let icon = "fa fa-info-circle";
        let circle = (this.props.circle) ? "-circle" : "";
        switch (this.props.type) {
            case "info":
                icon = "fa fa-info" + circle + " icon-info";
                break;
            case "warning":
                icon = "fa fa-question" + circle + " icon-warning";
                break;
            case "error":
                icon = "fa fa-exclamation" + circle + " icon-error";
                break;
            case "information":
                icon = "fa fa-info-circle icon-information";
                break;
            default:
                break;
        }
        if (this.props.contrast) icon += " white";
        icon += " " + this.props.position;
        let msgToHtml = this.props.message.trim().split('\n').join('</br>');
        return (
            <div className={"tooltip-container" + (this.props.colored ? " colored" : "")}>
                <i data-for={generatedID} data-html={true} data-tip={'<div style="margin: 0">' + msgToHtml + '</div>'}
                   className={icon}/>
                <ReactTooltip multiline={true} scrollHide={true} id={generatedID} effect="solid"
                              className={"tooltip tooltip-" + this.props.type}/>
            </div>
        )
    }
}

Tooltip.defaultProps = {
    position: "top right",
    type: "info",
    message: "",
    contrast: false,
    colored: false,
    circle: true,
};

export default Tooltip;